import { TodoPriority } from '../../../../@types/Todo';
export function setPriorityColor(priority) {
    switch (priority) {
        case TodoPriority.URGENT:
            return '#B13A41';
        case TodoPriority.HIGH:
            return '#CF940B';
        case TodoPriority.NORMAL:
            return '#4466FF';
        case TodoPriority.LOW:
            return '#87909E';
        default:
            return '#919EAB';
    }
}
export function setPriorityLabelColor(priority) {
    switch (priority) {
        case TodoPriority.URGENT:
            return 'error';
        case TodoPriority.HIGH:
            return 'warning';
        case TodoPriority.NORMAL:
            return 'info';
        case TodoPriority.LOW:
            return 'default';
        default:
            return 'primary';
    }
}
